$color-neutral-brightest: #ffffff;
$color-neutral-bright: #f7f7f7;
$color-neutral-lightest: #e7e7e7;
$color-neutral-light: #cfcfcf;
$color-neutral-primary: #9c9c9c;
$color-neutral-dark: #333e48;
$color-neutral-darker: #515260;
$color-neutral-darkest: #000000;

$color-red-bright: #ffccd5;
$color-red-light: #e8002a;
$color-red-primary: #9e1b32;
$color-red-dark: #6d1222;

$color-orange-bright: #ffe5cc;
$color-orange-light: #ff9633;
$color-orange-primary: #e77204;
$color-orange-dark: #994a00;

$color-yellow-bright: #fff4cc;
$color-yellow-light: #ffd332;
$color-yellow-primary: #f1c319;
$color-yellow-dark: #997800;

$color-green-bright: #dbffcc;
$color-green-light: #36b300;
$color-green-whatsapp: #29b33f;
$color-green-primary: #45842a;
$color-green-dark: #294d19;

$color-blue-bright: #cdf3ff;
$color-blue-light: #00ace6;
$color-blue-primary: #007298;
$color-blue-dark: #004d66;

$color-purple-bright: #f9ccff;
$color-purple-light: #9e00b3;
$color-purple-primary: #652f6c;
$color-purple-dark: #431f47;

$gradient-1: linear-gradient(
  180deg,
  $color-neutral-brightest,
  $color-neutral-bright
);
