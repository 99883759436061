$font-weight-regular: 400;
$font-weight-medium: 600;
$font-weight-bold: 700;

$font-size-xxxs: 1rem;
$font-size-xxs: 1.2rem;
$font-size-xs: 1.4rem;
$font-size-sm: 1.6rem;
$font-size-md: 1.8rem;
$font-size-lg: 2rem;
$font-size-xl: 2.4rem;
$font-size-xxl: 2.8rem;
$font-size-xxxl: 3rem;

$line-height-nano: 1.6rem;
$line-height-xxxs: 1.8rem;
$line-height-xxs: 2rem;
$line-height-xs: 2.2rem;
$line-height-sm: 2.4rem;
$line-height-md: 2.7rem;
$line-height-lg: 2.8rem;
$line-height-xl: 3.6rem;
$line-height-xxl: 3.8rem;
$line-height-xxxl: 4rem;

$icon-size-xxxs: 1.2rem;
$icon-size-xxs: 1.4rem;
$icon-size-xs: 1.6rem;
$icon-size-s: 2rem;
$icon-size-sm: 2.4rem;
$icon-size-md: 3.2rem;
$icon-size-lg: 4rem;
$icon-size-xl: 4.8rem;
$icon-size-giant: 6.4rem;
