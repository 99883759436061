@use '@/styles/tokens' as *;

@mixin typography(
  $size: $font-size-sm,
  $line-height: $line-height-sm,
  $weight: $font-weight-regular,
  $color: $color-neutral-dark,
  $style: normal
) {
  font-size: $size;
  line-height: $line-height;
  font-weight: $weight;
  font-style: $style;
  color: $color;
}
